@tailwind base;
@tailwind components;
@tailwind utilities;

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: start;
  font-size: 18px;
  background: #000000;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-img-wrapper {
  background-color: black;
  /* width: 100%; */
  /* height: 100%; */
}
.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-slide {
  width: 90%;
}

.swiper-slide:nth-child(2n) {
  width: 90%;
}

.swiper-slide:nth-child(3n) {
  width: 90%;
}
.swiper-pagination-bullet-active {
  background-color: #ffa500;
  width: 2rem;
  height: 0.5rem;
  border-radius: 3px;
}
.swiper-pagination-bullet {
  border: 4px solid orange;
}
/* for the video player */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

h1 {
  color: white;
}

video {
  width: 100%;
  height: 400px;
}

.video-wrapper {
  width: 100%;
  max-width: 700px;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  /* border-radius: 10px; */
}

.video-wrapper:hover .controls {
  transform: translateY(0%);
}

.controls {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  bottom: 30px;
  padding: 14px;
  width: 100%;
  max-width: 500px;
  flex-wrap: wrap;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  transform: translateY(150%);
  transition: all 0.3s ease-in-out;
}

.actions button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

.actions button i {
  background-color: none;
  color: white;
  font-size: 30px;
}

input[type="range"] {
  /* -webkit-appearance: none !important; */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  height: 4px;
  width: 350px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  cursor: pointer;
  height: 6px;
}

input[type="range"]::-webkit-range-progress {
  background: white;
}

.velocity {
  appearance: none;
  background: none;
  color: white;
  outline: none;
  border: none;
  text-align: center;
  font-size: 16px;
}

.mute-btn {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

.i {
  background-color: none;
  color: white;
  font-size: 20px;
}

/* Create a CSS file or add this to your existing CSS */
/* Modify the colors as needed */
.ant-pagination {
  .ant-pagination-item-link {
    color: #ffa500;
  }

  .ant-pagination-item-link:hover {
    color: white;
  }

  .ant-pagination-item-active {
    border-color: #ffa500;
    background-color: black;
    color: #ffa500;
  }

  .ant-pagination-item-active a {
    color: #ffa500;
  }
}

@media screen and (min-width: 768px) {
  .swiper-slide {
    width: 80%;
  }

  .swiper-slide:nth-child(2n) {
    width: 70%;
  }

  .swiper-slide:nth-child(3n) {
    width: 70%;
  }
}
